<template>
    <div>
        <template v-if="loading || panelGroupsPosLoading">
            <v-row v-for="(load, index) in [1, 2]" :key="load+index">
                <v-col col="6" class="col-lg-6 quote-skeleton">
                    <v-skeleton-loader :loading="true" type="image"></v-skeleton-loader>
                </v-col>
                <v-col col="6" class="col-lg-6 quote-skeleton">
                    <v-skeleton-loader :loading="true" type="image"></v-skeleton-loader>
                </v-col>
            </v-row>
        </template>
        <template v-if="!loading && !panelGroupsPosLoading">
            <template v-for="(panelGroup, groupIndex) in panelGroupLists">
                <v-row class="ma-0 mb-0" align="center" justify="center" :key="panelGroup.id">
                    <v-col cols="12" class="col-lg-6 pa-10 align-center justify-center d-flex position-relative car-quote-designs">
                        <div class="position-relative car-container">
                            <img :src="panelGroup.image" resize="contain" style="display: block;" width="100%"/>
                            <div :class="`car-${panelGroup.name.toLowerCase()}`">
                                <template v-for="(value, key, index) in tooltipModel[panelGroup.name]" >
                                    <div :id="`label-${key.toLowerCase()}`" :key="`${key}-${index}`">
                                        <v-tooltip bottom content-class="car-architecture-tooltip" attach :disabled="value.dent_total < 1">
                                            <template v-slot:activator="{ on, attrs }">
                                                    <v-chip v-bind="attrs" v-if="value && value.dent_total" large v-on="on" label color="#B54054"
                                                        class="pa-0 pa-2 h-auto white--text text-body-1" >{{value.dent_total}}</v-chip>
                                            </template>
                                            <div class="elevation-3">
                                                <v-list shaped>
                                                    <div class="d-flex align-center justify-space-between mr-2 ml-3 my-2">
                                                        <div dense v-text="'Dent'" class="mr-2 font-weight-bold"></div>
                                                        <div dense v-text="value.dent_total" class="font-weight-bold"></div>
                                                    </div>
                                                    <v-list-item-group dense>
                                                        <v-list-item  v-for="(item, i) in value.tooltip" :key="i" dense class="ml-5 mr-4 pr-0 pl-2 border-left">
                                                            <v-list-item-icon dense class="mr-3">
                                                                <v-icon :color="item.color" v-text="item.icon" class="ma-0 pa-0"></v-icon>
                                                            </v-list-item-icon>
                                                            <v-list-item-content dense>
                                                                <div class="d-flex align-center justify-space-between">
                                                                    <div dense v-text="item.text" class="mr-5"></div>
                                                                    <div dense v-text="item.dent"></div>
                                                                </div>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-list-item-group>
                                                </v-list>
                                            </div>
                                        </v-tooltip>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" class="col-lg-6 justify-space-between list-quote-card">
                        <v-row class="ma-0 pa-0" :class="panelGroup.panels.length == 1 ? 'align-center justify-center': ''" >
                            <template v-for="(panel, index) in panelGroup.panels">
                                <v-col cols="12" class="col-lg-6 col-md-6" :key="`${panel.id}`" v-if="!panel.hasLargeVariant || optModel.vehicle_type == panel.type">
                                    <v-card class="w-full h-full mr-2 pa-0" tile>
                                        <v-card-text class="text-center h-full">
                                            <h4 class="black--text text-uppercase">{{panel.label}}</h4>
                                            <h6 class="font-weight-medium black--text text-break" v-if="panelModel[panel.id] && panelModel[panel.id].repair_type">
                                                Selected Repair Type: <span class="text-uppercase">
                                                    {{ panelModel[panel.id].repair_type === 'replacement' ? 'Replace/Paint' : panelModel[panel.id].repair_type }}
                                                </span>
                                            </h6>
                                            <h6 class="font-weight-medium black--text text-break my-5" v-if="panelModel[panel.id] && !panelModel[panel.id].repair_type">
                                                No Damage Detected
                                            </h6>
                                            <v-row v-if="panel.editable" class="ma-0">
                                                <v-select v-model="panelModel[panel.id].category" outlined dense hide-details="auto" :items="categoriesList"
                                                    :menu-props="{ closeOnClick: true, bottom: true, offsetY: true, closeOnContentClick: true}"
                                                    class="text-capitalize w-75" @change="categoryChange($event, panel.id)">
                                                        <template slot="item" slot-scope="{ item }">
                                                            <span class="text-capitalize">{{ item }}</span>
                                                        </template>
                                                </v-select>
                                                <div class="cursor-pointer ml-2" @click="editCategory(groupIndex, index);">
                                                    <v-icon size="15" color="primary" class="pa-0 ma-0">mdi-content-save</v-icon>
                                                    <div class="primary--text font-weight-bold ma-0 pa-0" style="font-size: 10px">
                                                        Save
                                                    </div>
                                                </div>
                                            </v-row>
                                            <v-row class="ma-0 mb-7" :justify="panelModel[panel.id] && panelModel[panel.id].repair_type && panelModel[panel.id].repair_type != 'pdr'  ? 'center': 'space-between'"
                                                align="center" v-if="panelModel[panel.id] && panelModel[panel.id].repair_type">
                                                <h6 class="font-weight-medium black--text mb-0">
                                                    <span class="mr-2 text-capitalize" v-if="panelModel[panel.id].category && !panel.editable">{{panelModel[panel.id].category}}:</span>
                                                    <span>${{panelModel[panel.id].lineTotal}}</span>
                                                </h6>
                                                <div v-if="!panel.editable && panelModel[panel.id] && panelModel[panel.id].repair_type && panelModel[panel.id].repair_type == 'pdr'"
                                                    class="cursor-pointer" @click="editCategory(groupIndex, index)">
                                                    <v-icon size="15" color="primary" class="pa-0 ma-0">mdi-pencil</v-icon>
                                                    <div class="primary--text font-weight-bold ma-0 pa-0" style="font-size: 10px">
                                                        Edit
                                                    </div>
                                                </div>
                                            </v-row>
                                            <v-card-actions class="justify-center flex-column pa-0">
                                                <h6 class="font-weight-regular black--text mb-4">Change Repair Type:</h6>
                                                <template v-for="item in repairTypeActions">
                                                    <v-btn v-if="!panelModel[panel.id] || (!panelModel[panel.id].repair_type || panelModel[panel.id].repair_type != item.value)"
                                                        :key="`${panel.id}-${item.value}`" outlined color="primary"
                                                        elevation="2" class="ma-0 mb-3" block @click="changeRepairType(panel.id, item, groupIndex, index)">
                                                            {{item.text}}
                                                    </v-btn>
                                                </template>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </template>
                        </v-row>
                    </v-col>
                </v-row>
            </template>
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';

import { REPAIR_LISTS,  CATEGORY_LISTS, PANEL_PARTS } from '../service/quote-data';

export default {
    props: ['optModel', 'panelItems', 'loading'],
    emits: ['itemsUpdate', 'totalModelUpdate'],
    data: (vm) => ({
        repairTypeActions: REPAIR_LISTS || [],
        categoriesList: CATEGORY_LISTS || [],
        panelModel: {},
        panelGroupLists: [],
        dentPanel: null,
        scratchPanel: null,
        tooltipModel: {},
        initialLoading: true,
        panelParts: {}
    }),
    computed: {
        ...mapGetters(['panelGroupsPosition', 'quoteDetails', 'panelGroupsPosLoading'])
    },
    watch: {
        panelGroupsPosition(newVal) {
            if(newVal) this.panelParts = PANEL_PARTS(newVal);
        },
        loading(newVal) {
            if (!this.panelGroupsPosLoading && !newVal) this.setModelPanel();
        },
        panelGroupsPosLoading(newVal) {
            if (!newVal && !this.loading) this.setModelPanel();
        },
        optModel: {
            handler() {
                this.priceCalculation(this.panelModel);
            },
            deep: true
        }
    },
    created() {
        if(this.panelGroupsPosition) this.panelParts = PANEL_PARTS(this.panelGroupsPosition);
        if (this.quoteDetails && this.quoteDetails.client) this.setModelPanel();
    },
    methods: {
        setModelPanel() {
            if(this.panelItems) {
                this.panelModel = this.panelItems;
                this.pricePanelSet('item');
                this.groupLists('item');
                this.generateTotal();
            } else {
                this.pricePanelSet();
                this.groupLists();
            }
        },
        async pricePanelSet(type) {
            if((!this.dentPanel || !this.scratchPanel) && this.quoteDetails && this.quoteDetails.client) {
                this.dentPanel = this.quoteDetails.client.prices.dent;
                this.scratchPanel = this.quoteDetails.client.prices.scratch;
                await Object.keys(this.dentPanel).forEach(key => {
                    let groupPanel;
                    for ( const group of this.panelGroupsPosition){
                        groupPanel = group.panels.find( item => item.id == key )
                        if ( groupPanel ) break;
                    }
                    const DENT_ITEM = this.dentPanel[key],
                        PREV_ITEM = type && this.panelModel && this.panelModel[key] ? this.panelModel[key] : null,
                        PANEL = {
                            category: PREV_ITEM && PREV_ITEM.category ? PREV_ITEM.category  : DENT_ITEM.thresholdCategory || '',
                            type: 'dent',
                            vehicle_type: PREV_ITEM && PREV_ITEM.vehicle_type ? PREV_ITEM.vehicle_type : groupPanel.type || 'D',
                            hasLargeVariant:  PREV_ITEM && PREV_ITEM.hasLargeVariant ? PREV_ITEM.hasLargeVariant : groupPanel.hasLargeVariant || 0,
                            label: PREV_ITEM && PREV_ITEM.label ? PREV_ITEM.label : groupPanel.label || groupPanel.name,
                            car_panel: groupPanel.carPanel,
                            panel_id: key,
                            repair_type: PREV_ITEM && PREV_ITEM.repair_type ? PREV_ITEM.repair_type : DENT_ITEM.thresholdCategory ? 'pdr' : null,
                            aluminium_fee: 0,
                            metalic_fee: 0,
                            pearl_fee: 0,
                            lineTotal: 0,
                        };
                    PANEL['repair_fee'] = PANEL.category ?  Number(DENT_ITEM[PANEL.category]) : 0;
                    this.panelModel[key] = PANEL;
                });
            }
        },
        groupLists(type) {
            if (this.panelGroupLists.length == 0 && this.panelGroupsPosition.length && this.dentPanel) {
                if (!type) this.priceCalculation(this.panelModel);
                else this.$emit('itemsUpdate', this.panelModel);
                this.panelGroupLists = this.panelGroupsPosition.filter(group => {
                    group.panels = group.panels.filter(panel => {
                        panel.editable = false;
                        return (Object.keys(this.dentPanel)).indexOf(panel.id) !== -1;
                    });
                    return group.panels.length !== 0;
                });
                this.generateDamageModel();
            }
        },
        changeRepairType(id, item, groupIndex, panelIndex) {
            if (this.panelGroupLists[groupIndex].panels[panelIndex].editable) this.editCategory(groupIndex, panelIndex);
            let selectedPanel = this.panelModel[id];
            const TYPEMODEL = this.dentPanel, //selectedPanel.type == 'dent' ? this.dentPanel : this.scratchPanel,
                PANEL = TYPEMODEL[id];
            selectedPanel.repair_type = item.value;
            if (item.value !== 'pdr') {
                selectedPanel.category = null;
                selectedPanel.repair_fee = Number(PANEL[item.value]) || 0;
            } else {
                selectedPanel.category = PANEL.thresholdCategory;
                selectedPanel.repair_fee = Number(PANEL[selectedPanel.category]) || 0;
            }
            this.panelModel[id] = selectedPanel;
            this.priceCalculation(this.panelModel, id);
        },
        editCategory(groupIndex, panelIndex) {
            let panelGroup = this.panelGroupLists[groupIndex];
            panelGroup.panels[panelIndex].editable = !panelGroup.panels[panelIndex].editable
            this.panelGroupLists.splice(groupIndex, 1, panelGroup);
        },
        categoryChange(ev, panelId) {
            this.panelModel[panelId].repair_fee = Number(this.dentPanel[panelId][ev]) || 0;
            this.priceCalculation(this.panelModel, panelId);
        },
        async priceCalculation(model, panelID=null) {
            if(panelID) {
                model[panelID] = this.optCalcultaion(panelID);
            } else {
                await Object.keys(model).forEach(key => {
                    model[key] = this.optCalcultaion(key);
                });
            }
            this.panelModel = Object.assign({}, this.panelModel, model);
            this.$emit('itemsUpdate', this.panelModel);
            this.generateTotal();
        },
        optCalcultaion(id) {
          let panel = this.panelModel[id];

          if(panel.hasLargeVariant && panel.vehicle_type != this.optModel.vehicle_type){
            panel.lineTotal = panel.aluminium_fee = panel.metalic_fee = panel.pearl_fee = 0;

            return panel;
          }

          if (this.optModel.aluminium && this.dentPanel[id].aluminium && Number(panel.repair_fee)) {
                panel.aluminium_fee = (Number(this.dentPanel[id].aluminium || 0) / 100) * Number(panel.repair_fee);
            } else panel.aluminium_fee = 0;
            if (this.optModel.metallic && this.quoteDetails.client.sc_metallic) {
                panel.metalic_fee = (Number(this.quoteDetails.client.sc_metallic) / 100) * Number(panel.repair_fee);
            } else panel.metalic_fee = 0;
            if (this.optModel.pearl && this.quoteDetails.client.sc_pearl) {
                panel.pearl_fee = (Number(this.quoteDetails.client.sc_pearl) / 100) * Number(panel.repair_fee);
            } else  panel.pearl_fee = 0;
            panel.lineTotal = Number(panel.repair_fee) + panel.aluminium_fee + panel.metalic_fee + panel.pearl_fee;
            return panel;
        },
        generateTotal() {
            const MODEL = { grand: 0, replace: 0, pdr: 0, conventional: 0, p2p: 0 };
            Object.keys(this.panelModel).forEach(key => {
                MODEL.grand = MODEL.grand + Number(this.panelModel[key].lineTotal);
                if (this.panelModel[key] && this.panelModel[key].repair_type) {
                    if (this.panelModel[key].repair_type == 'pdr') {
                        MODEL.pdr = MODEL.pdr + Number(this.panelModel[key].lineTotal);
                    } else if (this.panelModel[key].repair_type == 'replacement') {
                        MODEL.replace = MODEL.replace + Number(this.panelModel[key].lineTotal);
                    } else if (this.panelModel[key].repair_type == 'conventional') {
                        MODEL.conventional = MODEL.conventional + Number(this.panelModel[key].lineTotal);
                    } else if (this.panelModel[key].repair_type == 'p2p') {
                        MODEL.p2p = MODEL.p2p + Number(this.panelModel[key].lineTotal);
                    }
                }
            });
            this.$emit('totalModelUpdate', MODEL);
        },
        generateDamageModel() {
            if (this.quoteDetails.measures && this.quoteDetails.measures.dents) {
                const DENT_DAMAGE = this.quoteDetails.measures.dents;
                this.panelGroupsPosition.map(group => {
                        this.tooltipModel[group.name] = {};
                    this.panelParts[group.name].map(item => {
                        const PANEL_MODEL = group.panels.filter(panel => panel.carPanel == item);
                        this.tooltipModel[group.name][item] = {};
                        if(PANEL_MODEL.length && DENT_DAMAGE[item]) {
                            this.tooltipModel[group.name][item].tooltip = [
                                { text: 'Oversize', icon: 'mdi-checkbox-blank', color: '#561329', dent: 0, scratch: 0 },
                                { text: 'Severe', icon: 'mdi-checkbox-blank', color: '#81182E', dent: 0, scratch: 0},
                                { text: 'Heavy', icon: 'mdi-checkbox-blank', color: '#C1484E', dent: 0, scratch: 0 },
                                { text: 'Medium', icon: 'mdi-checkbox-blank', color: '#CC9188', dent: 0, scratch: 0 },
                                { text: 'Light', icon: 'mdi-checkbox-blank', color: '#D8D1C1', dent: 0, scratch: 0 },
                            ]
                            this.tooltipModel[group.name][item].tooltip.map(tooltipItem => {
                                const COUNT_PART = DENT_DAMAGE[item][(tooltipItem.text).toLowerCase()] || null;
                                if (COUNT_PART && COUNT_PART.count) tooltipItem.dent = COUNT_PART.count;
                            });
                            this.tooltipModel[group.name][item].dent_total = this.tooltipModel[group.name][item].tooltip.reduce((n, {dent}) => n + Number(dent), 0);
                        }
                    })
                });
            }
        }
    }
}
</script>
